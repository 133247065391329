import { graphql,Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container,Modal } from "react-bootstrap"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import { formTracking } from "../components/Common/utils"
import GuidesCardComponent from "../components/GuidesCardComponent/GuidesCardComponent"
import LandingContent from "../components/LandingContent/LandingContent"
import OffplanCardSlider from "../components/OffplanCardSlider/OffplanCardSlider"
import OffplanColumnBlocks from "../components/OffplanColumnBlocks/OffplanColumnBlocks"
import OffplanContact from "../components/OffplanContact/OffplanContact"
import OffplanDetailGallery from "../components/OffplanDetailGallery/OffplanDetailGallery"
import OffplanDetailIcons from "../components/OffplanDetailIcons/OffplanDetailIcons"
import OffplanDetailsBanner from "../components/OffplanDetailsBanner/OffplanDetailsBanner"
import PaymentBlock from "../components/PaymentBlock/PaymentBlock"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import Seo from "../components/seo"
import logoBlack from "../images/logo-white.svg"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"
import AmmenitiesComponent from "../components/AmmenitiesComponent/AmmenitiesComponent"


const NewDevelopmentDetails = props => {
  const [shareIcons, setShareIcons] = useState(false)
  const [pageData, setPageData] = useState(props.data?.allNewdevelopments?.edges[0]?.node);
  const pageContext = props.pageContext
  const windowSearch= typeof window!=="undefined"?window.location.search:""
  const [loading, setLoading] = useState(() => {
    const checkurlParams = new URLSearchParams(windowSearch);
    const checkisPreview = checkurlParams.get('debug') === 'preview';
    return checkisPreview;
  });
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false) // since its string


  const offplanData = pageData
  // const offplanData = props.data?.strapiNewDevelopments
  const propertyData = props?.data?.allNewDevData?.edges
  const openGallery = props?.location?.state?.openGallery
  const dynamicData = {
    area: offplanData?.area,
    department: offplanData?.department,
    searchType: "sales",
  }

  const [show, setShow] = useState(offplanData?.status?.toLowerCase()==="sold out"?true:false)

  // const popUpData = props?.data?.strapiNewDevelopments?.popup_data

  const whatsappData =
    props?.data?.allStrapiSiteConfig?.edges?.length > 0 &&
    props?.data?.allStrapiSiteConfig?.edges[0]

  useEffect(() => {
    if (openGallery) {
      const getId = document.getElementById("off_plan_gallery")
      getId&&getId.scrollIntoView({ behavior: "smooth" })
    }
  }, [openGallery])
  const { isMobile, isLargeScreen } = useDeviceMedia()

  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }
  const shareurl = typeof window !== "undefined" ? window.location.href : ""

  useEffect(() => {
    // Check if the preview query parameter is present
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    // make this only when preview params available
    if (isPreview) {
      setIsPreviewEnabled(true)
      const apiUrl = process.env.GATSBY_STRAPI_SRC+'/api/new-development/'+urlParams
      .get('strapi_id')+'?populate[0]=upload_image&populate[1]=images&populate[2]=property_brochure&populate[3]=icon_items.icon_list.image&populate[4]=text_block&populate[5]=column_blocks.left_right_block.image&populate[6]=payment_block.payment_items&populate[7]=property_cards.items.image&populate[8]=popup_data.image&populate[9]=icon_items.icon_list.ctas&&populate[10]=popup_data.cta&populate[11]=property_cards.items.link&populate[12]=property_developer_logo&populate[13]=ammenities_data.icon_list.image&populate[14]=secondary_blocks.left_right_block.image&populate[15]=property_cards.items.link&populate[16]=qr_code&populate[17]=seo_module';
      const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

      const fetchData = async () => {
        try {
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
            },
          });
          const previewData = await response.json();
          setLoading(false)
          setEnablePreview(false)
          let myPrevData;
          if(pageData){
            myPrevData = pageData
          } else {
            myPrevData = {};
            myPrevData.image = {}
            myPrevData.upload_image = {}
            myPrevData.property_brochure = []
            myPrevData.icon_items.icon_list=[]
            myPrevData.text_block={}
            myPrevData.column_blocks.left_right_block=[]
            myPrevData.payment_block.payment_items=[]
            myPrevData.property_cards.items=[]
            myPrevData.popup_data={}
            myPrevData.popup_data.image={}
            myPrevData.icon_items={}
            // myPrevData.icon_items.icon_list=[]
            myPrevData.text_block={}
            myPrevData.column_blocks={}
            myPrevData.column_blocks.left_right_block=[]
            myPrevData.payment_block={}
            myPrevData.payment_block.payment_items=[]
            myPrevData.property_cards={}
            myPrevData.property_cards.items=[]
            myPrevData.ammenities_data={}
            myPrevData.ammenities_data.icon_list=[]
            myPrevData.secondary_blocks={}
            myPrevData.secondary_blocks.left_right_block=[]

            myPrevData.id = urlParams.get('strapi_id')

          }
          if(previewData){
            //skip fields like id, which are dont need to be updated
            myPrevData.images=previewData?.data?.attributes?.images?.length>0?previewData?.data?.attributes?.images:""

            myPrevData.upload_image = previewData?.data?.attributes?.upload_image?.data?.attributes
            myPrevData.property_developer_logo = previewData?.data?.attributes?.property_developer_logo?.data?.attributes
            myPrevData.banner_title =  previewData?.data?.attributes?.banner_title
            myPrevData.developer = previewData?.data?.attributes?.developer
            myPrevData.property_brochure = previewData?.data?.attributes?.property_brochure?.data?.length>0?previewData?.data?.attributes?.property_brochure?.data:""
            myPrevData.banner_content=previewData?.data?.attributes?.banner_content
            myPrevData.additional_email=previewData?.data?.attributes?.additional_email
            myPrevData.project_title = previewData?.data?.attributes?.project_title
            myPrevData.text_block = previewData?.data?.attributes?.text_block
            myPrevData.icon_items = previewData?.data?.attributes?.icon_items
            myPrevData.column_blocks = previewData?.data?.attributes?.column_blocks
            myPrevData.payment_block = previewData?.data?.attributes?.payment_block
            myPrevData.price= previewData?.data?.attributes?.price
            if(previewData?.data?.attributes?.qr_code?.length>0){
              myPrevData.qr_code = previewData?.data?.attributes?.qr_code[0]
              }

            myPrevData.ammenities_data = previewData?.data?.attributes?.ammenities_data
            myPrevData.secondary_blocks =  previewData?.data?.attributes?.secondary_blocks
            
            myPrevData.property_cards = previewData?.data?.attributes?.property_cards
            myPrevData.form_title = previewData?.data?.attributes?.form_title
            myPrevData.whatsapp = previewData?.data?.attributes?.whatsapp
            myPrevData.phone_number = previewData?.data?.attributes?.phone_number
            myPrevData.form_content = previewData?.data?.attributes?.form_content
            myPrevData.permit_number = previewData?.data?.attributes?.permit_number
            myPrevData.popup_data = previewData?.data?.attributes?.popup_data
            myPrevData.area = previewData?.data?.attributes?.area
            //merge the new data into the original data so that we dont have to worry about the template flow
            setPageData(pageData => ({ ...pageData, myPrevData }));
            sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.data?.attributes?.seo_module))

          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } else {
      setLoading(false);
      setIsPreviewEnabled(false)
       sessionStorage.removeItem("previewMeta")
    }
  }, [])


 


  let urlParamsVal = ""
  let isPreviewVal = false

  if(typeof window !== "undefined"){
     urlParamsVal = new URLSearchParams(window.location.search);
    isPreviewVal = urlParamsVal.get('debug') === 'preview';
  }

  if(isPreviewVal){
    if(loading){
        return (
          <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
              <div id="loader" class="new-loader">
                <img
                  className="logo-white loader-logo"
                  src={logoBlack}
                  alt="logo"
                />
              </div>
            </div>
          </section>
        )
    }
  }

  if(enablePreview){
    return null
  }

  const offPlanWhatsapp = offplanData?.whatsapp?offplanData?.whatsapp:"971502304695";
  const offplanPhoneNum = offplanData?.phone_number?offplanData?.phone_number: "971502304695"
  let pageUrl = typeof window !== "undefined" ? window.location.href : ""

  const text = `Hi, I saw this property on haus & haus ${pageUrl} and would like to know more.`

  const handleClose = () =>{
    setShow(false)
  }

  return (
    <Layout  dynamicData={dynamicData} footerClass="property-footer">
      <OffplanDetailsBanner offplanData={offplanData} />
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            department={"new_developments"}
            alias={"sales"}
            type="property-details-page"
            pagename={offplanData?.project_title}
          />
          <div
            className="share-property-details"
            onClick={() => openShareicons()}
          >
            <i className="icon grey-share-icon" />
            <span>{isMobile ? "Share" : "Share Property"}</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={shareurl}
              />
            )}
          </div>
        </div>
      </Container>

      <OffplanDetailIcons isPreviewEnabled={isPreviewEnabled} iconData={offplanData?.icon_items} />
      <OffplanDetailGallery
        openGallery={props?.location?.state?.openGallery}
        imageData={offplanData?.images}
        offplanData={offplanData}
      />
      {offplanData?.text_block&& 
        <LandingContent isNewDevDetails isPreviewEnabled={isPreviewEnabled} data={offplanData?.text_block} />
      }

      {offplanData?.column_blocks?.left_right_block?.length > 0 && (
        <OffplanColumnBlocks
          data={offplanData?.column_blocks}
          offplanData={offplanData}
          isPreviewEnabled={isPreviewEnabled}
        />
      )}
      {offplanData?.ammenities_data&&
      <AmmenitiesComponent isPreviewEnabled={isPreviewEnabled} ammentiesData={offplanData?.ammenities_data} />
      } 
      {offplanData?.secondary_blocks?.left_right_block?.length > 0 && 
      <OffplanColumnBlocks
      data={offplanData?.secondary_blocks}
      offplanData={offplanData}
      isPreviewEnabled={isPreviewEnabled}
      isSecondaryBlocks
      />
      }
      {offplanData?.payment_block&&
      <PaymentBlock isPreviewEnabled={isPreviewEnabled} paymentData={offplanData?.payment_block} />
      }
      <OffplanContact isPreviewEnabled={isPreviewEnabled} offplanData={offplanData} whatsappData={whatsappData} />
      <OffplanCardSlider
        offplanData={offplanData}
        propertyData={propertyData}
      />

      <GuidesCardComponent
        data={offplanData?.property_cards}
        offplanData={offplanData}
        isPreviewEnabled={isPreviewEnabled}
        isNewDevDetails
      />

        {/* {isLargeScreen && ( */}
        <div className="property-footer-ctas offplan d-flex d-xl-none">
          <a className="e_mail" href="#offplan-contact-form">
            <button className="button button-filled-green">
              {" "}
              <span>Register Your Interest</span>
            </button>
          </a>

          {offPlanWhatsapp && (
            <a
              className="whats-app"
              target="_blank"
              href={`https://wa.me/${offPlanWhatsapp?.replace(
                /[^A-Z0-9]/gi,
                ""
              )}?text=${encodeURIComponent(text)}`}
            >
              <button className="button button-outline-white">
                {" "}
                <i className="icon black-whatsapp-new" />
               {/* <span className="text-media">{isMobile?"":"Whatsapp"}</span> */}
               <span className="text-media d-none d-md-block">Whatsapp</span>
               <span className="text-media d-block d-md-none"></span>
              </button>
            </a>
          )}

          {offplanPhoneNum && (
            <a className="call_num" href={`tel:${offplanPhoneNum}`}>
              <button className="button button-outline-white">
                {" "}
                <i className="icon black-call-icon" />
                {/* <span className="text-media">{isMobile?"":"Call"}</span> */}
                <span className="text-media d-none d-md-block">Call</span>
                <span className="text-media d-block d-md-none"></span>
              </button>
            </a>
          )}
        </div>
        {/* )} */}

        <Modal
          show={show}
          backdrop="static"
          centered
          dialogClassName="modal-popup-form sold_popup_newdev"
        >
          <Modal.Body>
            <div className="popup-form-wrapper">
              <div
                className="close-modal"
                onClick={() => handleClose()}
              >
                <i className="icon black-cancel-icon"></i>
              </div>
              <div className="new-dev-form-content">
                <p>This off plan project is now sold out. Units may still be
                   available through our resale team – contact us below to find out more.
                </p>
                <Link to={`/contact/?open-form=off-plan-and-investments`} className="button button-filled-green">Contact Us</Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </Layout>
  )
}

export const Head = props => {
  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }


  //seo title, h1 and desc prepare, this can send to utilis function later
  const seoData = props.data?.allNewdevelopments?.edges[0].node
  const pageData = props.data?.allNewdevelopments?.edges[0].node

  const seoTtile = seoData?.seo_module?.metaTitle;
  const seoDescription = seoData?.seo_module?.metaDescription

  let pagetitle = `Off plan | ${seoData.development_type} for sale in ${seoData?.project_title}`
  let pagemetadesc = `Off plan | ${seoData.development_type} for sale in ${seoData?.project_title}`

  const [preMetaData, setPreMetaData] = useState("")
  const [preMetaTitle, setPreMetaTitle] = useState(seoTtile?seoTtile:pagetitle)


  let pageUrl =
    process.env.GATSBY_SITE_URL +
    (props?.location?.pathname).replace(/\/?$/, "/")
  // let myimgtransforms = ""
  // if (props.data.strapiNewDevelopments?.imagetransforms?.images_Transforms) {
  //   myimgtransforms = JSON.parse(
  //     props.data.strapiNewDevelopments?.imagetransforms?.images_Transforms
  //   )
  // }

  // let myimgtransforms1 = ""
  // if (props.data.strapiNewDevelopments?.imagetransforms?.metaog_Transforms) {
  //   myimgtransforms1 = JSON.parse(props.data.strapiNewDevelopments?.imagetransforms?.metaog_Transforms)
  // }
  
  const getImg=pageData?.upload_image?.url?pageData?.upload_image?.url:
  pageData?.images?.length>0?pageData?.images[0]?.url:""

  let imageUrlData=getImg
  let imageExtension=imageUrlData?imageUrlData?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""
  const findImage = getImg?.substring(getImg?.indexOf("i"));

  let imageUrl=getImg

  // if(myimgtransforms1){
  //   const ProcessedResult=findImage&&myimgtransforms1[findImage]?myimgtransforms1[findImage][ext]:""
  //   imageUrl=ProcessedResult?ProcessedResult["600x400"]:getImg
  // }
  // else if(myimgtransforms){
  //   // ogRender()
  //   const ProcessedResult=findImage&&myimgtransforms[findImage]?myimgtransforms[findImage]["webp"]:""
  //   imageUrl=ProcessedResult?ProcessedResult["336x220"]:getImg
  // }
  
  var ldJson = {
    "@context": "https://schema.org",
    "@type": "SingleFamilyResidence",
    "@id": "SingleFamilyResidence",
    name: pageData?.banner_title,
    description: pagemetadesc,
    numberOfRooms: pageData?.development_type,
    occupancy: {
      "@type": "QuantitativeValue",
      minValue: 1,
      maxValue: 5,
    },
    numberOfBedrooms: pageData?.development_type,
    address: {
      "@type": "PostalAddress",
      addressCountry: "Dubai",
      addressLocality: pageData?.area,
    },
  }

  let imageObjectScript = {
    "@context": "https://schema.org",	
    "@type": "ImageObject",	
    "@id": "ImageObject",
    contentUrl: getImg,
    url: pageUrl,
    name: `${pageData?.banner_title}`,	
    description: pagemetadesc,
    height: "838",	
    width: "1800",	
    author: {	
      "@type": "Organization",	
      name: "haus & haus"	
    }
  }

  //new ggfx image
  const propertyImageUrl = pageData?.images
  const getImage =
  pageData?.upload_image?.url ? pageData?.upload_image?.url?.replace(
    "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
    "",
  )
  :
  pageData?.images?.length > 0 && pageData?.images[0].url
      ? pageData?.images[0].url.replace(
          "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
          "",
        )
      : propertyImageUrl?.length>0 && propertyImageUrl[0].url ?
        propertyImageUrl[0].url.replace(
        "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
        "",
      )
      :
      ""
  
  const filterImage =
    getImage && pageData?.ggfx_results?.length > 0
      ? pageData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
      : ""
     

  // filter image with image size
  const getOGImg =
    filterImage.length > 0 && filterImage[0].transforms?.length > 0
      ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
      : ""

  const webPImage =
  filterImage.length > 0 && filterImage[0].transforms?.length > 0
    ? filterImage[0].transforms.filter((item) => item.transform === "696x394")
    : ""

  const preloadImage = filterImage.length > 0 && filterImage[0].transforms?.length > 0
  ? filterImage[0].transforms.filter((item) => item.transform === "1800x838")
  : ""

  const preloadMobile = filterImage.length > 0 && filterImage[0].transforms?.length > 0
  ? filterImage[0].transforms.filter((item) => item.transform === "400x610")
  : ""

    const defaultImage = filterImage.length > 0 && filterImage[0].transforms?.length > 0
    ? filterImage[0].transforms[0]?.url:""

   //to get image in uploaded format   
  const ogRenderNew = ()=>{
    GetTransformedUrl({
      srcCftle: findImage,
      format: ext,
      transform:"600x400",
      id: pageData.strapi_id,
      field: "metaog",
      contentType: "new-developments"
    })
  }

  useEffect(()=>{
    if(!getOGImg?.length>0)
        ogRenderNew()
  },[]) 

  //----

  const metaImage = pageData?.algoliaData?.metaog?.length>0?pageData?.algoliaData?.metaog[0]["_600x400"]:""

  const pageImage=getOGImg?.length>0?getOGImg[0].url:""
  const fallBackImage = webPImage?.length>0 ? webPImage[0]?.url : ""

 
  //breadscrumb schema
  let dynamicmenu1 ="Home"
  let dynamicmenu1Url = "https://www.hausandhaus.com/"
  let dynamicmenu2 = ""
  let dynamicmenu2Url=""
  let dynamicmenu3 = pageData?.project_title
  let dynamicmenu3Url = `${process.env.GATSBY_SITE_URL}/off-plan/properties/for-sale/in-dubai/${pageData?.slug}/${pageData?.crm_id}`
  

  if( pageData.department==="Offplan"){
    dynamicmenu2 = "Off Plan for Sale";
    dynamicmenu2Url = `${process.env.GATSBY_SITE_URL}/off-plan/properties/for-sale/in-dubai/`
  }

  let breadcrumbJson = {
    "@context": "https://schema.org",	
  "@type": "BreadcrumbList",	
  "@id": "BreadcrumbList",
  itemListElement: [	
    {	
      type: "ListItem",	
      position: 1,	
      name: dynamicmenu1,	
      item: dynamicmenu1Url	
    },	
  ]	
  }

  if(dynamicmenu2){
    breadcrumbJson.itemListElement.push(
      {
        type: "ListItem",	
        position: 2,	
        name: dynamicmenu2,	
        item: dynamicmenu2Url	
      }
    )
  }

  if(dynamicmenu3 && dynamicmenu3Url){
    breadcrumbJson.itemListElement.push(
      {
        type: "ListItem",	
        position: 3,	
        name: dynamicmenu3,	
        item: dynamicmenu3Url	
      }
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
            setPreMetaData(parsedData);
            if(parsedData?.metaTitle){
              setPreMetaTitle(parsedData?.metaTitle)
            }
          }
        } catch (error) {
          console.error("Error parsing session storage data:", error);
        }
      }
    }, 4000);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
    document.title =  `${preMetaTitle} | haus & haus`;
    }
}, [preMetaTitle]);

  
  const previewMetaDesc = preMetaData?.metaDescription ? preMetaData.metaDescription : ""

  return (
    <>
    <link rel="preload" href={preloadImage?.length>0&&preloadImage[0]?.url} as="image" type="image/webp"  /> 
      <link rel="preload" href={preloadMobile?.length>0&&preloadMobile[0]?.url} as="image" type="image/webp"  /> 
    <Seo newDevelopmentDetails title={(isPreview && preMetaTitle) ? preMetaTitle 
       : seoTtile ? seoTtile + " | " : pagetitle + " | "} 
    description={previewMetaDesc ? previewMetaDesc : seoDescription?seoDescription:pagemetadesc} 
    imageUrl={metaImage?metaImage:pageImage?pageImage:fallBackImage?fallBackImage:defaultImage?defaultImage:imageUrl} >
      <link rel="canonical" href={pageUrl} />
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
      <script
        type="application/ld+json"
        // dangerouslySetInnerHTML={{ __html: JSON.stringify(imageObjectScript) }}
      >
        {JSON.stringify(imageObjectScript, null, 2)}
      </script>
       <script
        type="application/ld+json"
        // dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbJson) }}
      >
        {JSON.stringify(breadcrumbJson, null, 2)}
      </script>
    </Seo>
    </>
  )
}

export default NewDevelopmentDetails

export const query = graphql`
  query ($crm_id: String) {
    allNewDevData: allNewdevelopments(
      filter: {search_type: {eq: "sales"}}
      sort: {fields: updatedAt, order: DESC}
    ) {
      edges {
        node {
          developer
          id
          price
          area
          banner_title
          crm_id
          slug
          status
          search_type
          department
           images{
          url
        }
        upload_image {
          url
        }
        ggfx_results {
          src_cftle
          transforms {
            url
            format
            transform
          }
        }
        }
      }
    }
    allNewdevelopments(filter: {crm_id: { eq: $crm_id }}) {
    edges {
      node {
        developer
        id
        area
        whatsapp
        crm_id
        phone_number
        status
        additional_email
        department
        development_type
        property_brochure {
          url
        }
        algoliaData {
          metaog {
            _600x400
            url
          }
        }
         seo_module {
          metaDescription
          metaTitle
        }
        images{
          url
        }
        upload_image {
          url
        }
        ggfx_results {
          src_cftle
          transforms {
            url
            format
            transform
          }
        }
        property_developer_logo {
          url
        }
        qr_code {
          validationQr
          permitNumber
        }
        text_block {
          title
          description
        }
        banner_title
        latitude
        longitude
        price
        project_title
        slug
        strapi_id
        banner_content
        form_title
        form_content
        ammenities_data {
          title
          content
          icon_list {
            heading
            image {
              url
            }
          }
        }
       

        secondary_blocks {
        left_right_block {
          description
          
          image {
            url
          }
          longitude
          latitude
          title
          layout
          video_url
        }
      }

        payment_block {
          title
          content
          payment_items {
            payment_text
            payment_value
          }
        }
        property_cards {
          heading
          items {
            link_type
            cta_title
            custom_link
            content
            image {
              url
            }
          }
        }
          text_block {
          description
          title
        }
        column_blocks {
          left_right_block {
            cta_label
            image {
              url
            }
            description
            title
            longitude
            layout
            latitude
          }
        }
        icon_items {
          content
          title
          icon_list {
            heading
            image {
              url
            }
            sub_content
            ctas {
            custom_link
            link_type
            title
          }
          }
        }
      }
    }
  }
    
    allStrapiSiteConfig {
      edges {
        node {
          offplan_whatsapp_msg
        }
      }
    }
  }
`