import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./OffplanDetailIcons.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanDetailIcons = ({ iconData,isPreviewEnabled }) => {
  const { isLargeScreen } = useDeviceMedia()

  const iconHeading = [
    { newheading: "Completion Date" },
    { newheading: "Prices from" },
    { newheading: "Developer" },
    { newheading: "Location" },
    { newheading: "Development Type" },
    { newheading: "Floorplan" },
    { newheading: "Siteplan" },
  ]

  let combinedData = iconData?.icon_list?.map((item, index) => {
    return { ...item, newheading: item?.heading }
  })
  return (
    <>
      {(combinedData?.length>0||iconData)&&
    <div className="offplan-details-iconwrapper">
      <Container>
        <div className="offplan-icon-module">
          <div
            className="content-section_offplan"
          >
            <h2 className="heading">{iconData?.title}</h2>
            {(iconData?.content||(isPreviewEnabled&&iconData?.content))&&
            <p className="description">
              <ContentModule Content={iconData?.content} />
            </p>
            }
          </div>
          {combinedData?.length > 0 && (
            <div className="icon-section_offplan">
              {combinedData?.map((item, index) => {
                let iconName =
                  item.newheading === "Completion Date"
                    ? "new-dev-date"
                    : item.newheading === "Prices from"
                    ? "new-dev-price"
                    : item.newheading === "Developer"
                    ? "new-dev-developer"
                    : item.newheading === "Location"
                    ? "new-dev-location"
                    : item.newheading === "Development Type"
                    ? "new-dev-devtype"
                    : item.newheading === "Floorplan"
                    ? "new-dev-floorplan"
                    : item.newheading === "Siteplan"
                    ? "siteplan"
                    : ""
                return (
                  <>
                    <ScrollAnimation
                      className="item_list-offplan"
                      animateIn="animate__slideInUp"
                      animateOnce
                      delay={index * 100}
                      offset={100}
                    >
                      {(item?.image?.url||item?.image?.data?.attributes?.url)?
                        <img src={item?.image?.url||item?.image?.data?.attributes?.url} alt={item?.heading}/>
                      :
                      <div className="icon-offplan-holder">
                        <i className={`icon ${iconName}`} />
                      </div>
                      }
                      <h4>{item.newheading}</h4>
                      {item?.ctas?.link_type === "onclick" ? (
                        <p className="click-icons">
                        <a href={item?.ctas?.custom_link} target="_blank">
                          {item?.ctas?.title}
                        </a>
                        </p>
                      ) : (
                        (item?.sub_content||(isPreviewEnabled&&item?.sub_content))&&
                        <p className="cta_title">
                          <ContentModule
                            Content={item?.sub_content}
                            />
                        </p>
                      )}
                    </ScrollAnimation>
                    {index % 2 === 0 && isLargeScreen ? (
                      <ScrollAnimation
                        className="vertical-line"
                        animateIn="animate__slideInUp"
                        animateOnce
                        delay={100}
                        offset={100}
                      >
                      </ScrollAnimation>
                    ) : (
                      (index + 1) % 4 !== 0 &&
                      !isLargeScreen && (
                        <ScrollAnimation
                          className="vertical-line"
                          animateIn="animate__slideInUp"
                          animateOnce
                          delay={100}
                          offset={100}
                        >
                        </ScrollAnimation>
                      )
                    )}
                  </>
                )
              })}
            </div>
          )}
        </div>
      </Container>
    </div>
      }
    </>
  )
}

export default OffplanDetailIcons
